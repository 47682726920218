import React from "react";
import { useSumupLoginMutation } from "../state/slices/usersApiSlice";

const SumUpLogin = () => {

  const [sumupLogin, {data, error, isLoading}] = useSumupLoginMutation();


  const handleSumUpLogin = async () => {
    try{
        const response =   await sumupLogin().unwrap();
        console.log(response)
        if (response.status === 200){
            
            window.location.href = response.url
        }
    }
    catch (error)  {
        console.log("Error", error);
    }
  };

  return (
    <div>
      <button onClick={handleSumUpLogin}>Connect SumUp</button>
    </div>
  );
};

export default SumUpLogin;
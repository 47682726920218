import { useDownloadReportsExcelMutation } from '../state/slices/fileApiSlice';





// const forceDownload = (response, title) =>{
//     console.log(response)
//     const url = window.URL.createObjectURL(new Blob([response.data]))
//     const link = document.createElement('a')
//     link.href = url
//     link.setAttribute('download', title+'.pdf')
//     document.body.appendChild(link)
//     link.click()


// }

// const downloadWithAxios = (url, title)=>{
//     axios({
//         method: 'get',
//         url,
//         responseType: 'arraybuffer'
//     }).then((response)=>{
//         forceDownload(response, title)
//     }).catch((error)=> console.log(error))

// }









const DownloadButton = () => {
  const [downloadReportsExcel, { error, isLoading }] = useDownloadReportsExcelMutation();

  const downloadExcelFile = async (start_date, end_date) => {
      try {
          // Call the mutation and await the blob response
          const blob = await downloadReportsExcel({ start_date, end_date });
          console.log(blob, '<<---blob')

      // Create a download link for the Excel file
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', 'transaction_data.xlsx'); // Set the file name

      // Programmatically trigger the download
      document.body.appendChild(link);
      link.click();

      // Clean up the DOM element
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading the file:', error);
    }
  };

  const handleDownload = () => {
    const startDate = '2022-01-01'; // Replace with actual start date
    const endDate = '2024-01-31'; // Replace with actual end date
    downloadExcelFile(startDate, endDate);
  };

  return (
    <button onClick={handleDownload} disabled={isLoading}>
      {isLoading ? 'Downloading...' : 'Download Excel File'}
    </button>
  );
};

export default DownloadButton;

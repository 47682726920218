import React, { useState, useEffect } from "react";
import { Container, TextField, Button, Typography, Box } from "@mui/material";
import toaster from "../../utils/toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import './style.scss'
import olivepod2 from '../../images/olivepod2.webp'
import { useFormik } from "formik";
import { forgotPasswordSchema, loginSchema, resetPasswordSchema } from "../../utils/form_schemas";
import {useLoginMutation, useTestMutation, useGetUserMutation, useForgotPasswordMutation, useResetPasswordMutation} from '../../state/slices/usersApiSlice'
import { setCredentials } from "../../state/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../state/slices/userSlice";
import { grey } from "@mui/material/colors";
import { TailSpin } from "react-loader-spinner";

const ResetPassword = () => {

  const { uidb64, token } = useParams();
  
  const [msg, setMsg] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [resetPassword, {data, error, isLoading}] = useResetPasswordMutation();

      



  const {isAuthenticated} = useSelector(state => state.auth)


 
  useEffect(() => {
    setMsg(null)
    if (isAuthenticated) {
      // navigate('/');
      window.location.href = '/';

      // window.location.reload()
    }

    
  }, [isAuthenticated, navigate]);

  

  const submitHandler = async (req, res) => {
    try {
      const data = {password1: values.password, password2: values.confirm_password, uidb64, token}
      const response = await resetPassword(data).unwrap()      
      
        setMsg(response.msg)




    } catch (error) {
      toaster('error', error.data?.msg)
        console.log("eeeeeeeeeee",error)
    }
    
  }


  const  initialValues = {
    password: '',
    confirm_password: ''
  }
  const {values, errors, touched, handleBlur, handleChange, handleSubmit} =  useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: submitHandler

  })


  

////////////////=======================================================================///////////////////////////////////////////////////
  return (
    <div className="login-container" >
      <Box bgcolor="red" className="gradient-box" sx={{flexBasis:'30%', display:'flex', justifyContent:'center', alignItems:'center'}} >

            <img src={olivepod2} height="70px" />
        
      </Box>
      <Container component="main" maxWidth="xs" sx={{flexBasis:'70%', display:'flex', justifyContent:'center', alignItems:'center'}}  >

{
  isLoading? (<TailSpin color="#56CABB" width={80} height={80} />):
        
          msg? (<h1>{msg}</h1>):
        <Box
          sx={{
            // marginTop: -10,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h4">
            Enter your new password
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={values.password}
              onBlur={handleBlur}
              onChange={handleChange}
              helperText={errors?.password && touched?.password ? errors.password : null}
              error={errors.password && touched.password ? true : false}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="confirm_password"
              label="Confirm Password"
              type="password"
              id="confirm_password"
              autoComplete="new-password"
              value={values.confirm_password}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errors?.confirm_password && touched?.confirm_password
                  ? errors.confirm_password
                  : null
              }
              error={
                errors.confirm_password && touched.confirm_password ? true : false
              }
            />
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <Link to="/login">
               Back to login?
              </Link>
            </Box>

            <Button
              onClick={handleSubmit}
              fullWidth
              variant="contained"
              disabled={isLoading}
              sx={{ mt: 3, mb: 2, fontSize: 14,  }}
            >
              {isLoading? '. . . .' : 'Update password'} 
            </Button>
          </Box>
          {/* <p onClick={handleTest} >TEst</p> */}
        </Box>
        
}

    
      </Container>
    </div>
  );
};

export default ResetPassword;

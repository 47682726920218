import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useGetCampaignMutation } from "../../state/slices/campaignApiSlice";
import { Box, Input, TextField, Typography } from "@mui/material";
import DonationButton from "../../components/DonationButton.jsx";

import Cards from "react-credit-cards-2";
import "react-credit-cards-2/dist/lib/styles.scss";
import { useCreateCheckoutMutation } from "../../state/slices/checkoutApiSlice.js";
import {  useSelector } from "react-redux";

const WebCampaign = () => {
  const { id } = useParams();
  const location = useLocation();
  const [getCampaign, { data, error, isLoading }] = useGetCampaignMutation();
  const [campaign, setCampaign] = useState();
  const [customAmount, setCustomAmount] = useState(null);
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [useremail, setUseremail] = useState();



  const [createCheckout, { checkoutdata  , checkouterror, chekoutisLoading}]  = useCreateCheckoutMutation();

  const [cardState, setCardState] = useState({
    number: "",
    expiry: "",
    cvc: "",
    name: "",
    focus: "",
  });

  const handleInputCardChange = (evt) => {
    const { name, value } = evt.target;

    setCardState((prev) => ({ ...prev, [name]: value }));
  };

  const handleInputCardFocus = (evt) => {
    setCardState((prev) => ({ ...prev, focus: evt.target.name }));
  };

  useEffect(() => {
    fetchCampaign();
  }, []);

  const fetchCampaign = async () => {
    try {
      const res = await getCampaign({ id }).unwrap();
      if (res.success) {
        const rescampaign = res.campaign;
        const parsedButtons = JSON.parse(rescampaign.buttons);
        setCampaign({ ...rescampaign, buttons: parsedButtons });
        setUseremail(res.user_email)
      }
    } catch (error) {
      console.error("Failed to fetch campaign:", error);
    }
  };



  const handleAmountBtnClicked = async (btn) => {

    const amountToSet = customAmount || btn; // Determine the correct amount
    setSelectedAmount(amountToSet);

    console.log(useremail, ' <-------- user')
    const data = {amount: amountToSet, currency: 'GBP', pay_to_email: useremail, campaign_id:campaign?.id} 
    const res = await createCheckout(data)   // create a new hash id and pass here    

    console.log(res, "< +++ res from checkout api")
    // create



  }

  if (chekoutisLoading){
    return <div> Please Wait  Checking Out</div>
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error</div>;
  }

  if (selectedAmount) {
    return (
      <>
        {/* <button  onClick={()=> setSelectedAmount(null)} >back</button> */}
        {/* <h1>Enter Card Detail </h1> <h2> {selectedAmount}</h2> */}
        <Box
          position="fixed"
          left={0}
          right={0}
          display="flex"
          justifyContent="center"
          paddingX={10}
        >
          <Box
            display="flex"
            flexDirection="column"
            // bgcolor={campaign.background_colour}
            justifyContent="center"
            // alignItems="center"
            paddingTop={10}
            gap={10}
            zIndex={1}
          >
            <button onClick={() => setSelectedAmount(null) }  > back </button> 
            <Box  maxWidth={600} >
              <Cards
                number={cardState.number}
                expiry={cardState.expiry}
                cvc={cardState.cvc}
                name={cardState.name}
                focused={cardState.focus}
              />
            </Box>
            <Box display="flex" flexDirection="column" gap={1}>
              <TextField
                type="tel"
                placeholder="Card Number"
                name="number"
                value={cardState.number}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
                  if (value.length <= 22) {
                    // Limit to 22 digits
                    setCardState({ ...cardState, number: value });
                  }
                }}
                onFocus={handleInputCardFocus}
              />

              <TextField
                type="text"
                placeholder="Name"
                name="name"
                value={cardState.name}
                onChange={handleInputCardChange}
                onFocus={handleInputCardFocus}
              />

              <TextField
                type="tel"
                placeholder="Valid Thru"
                name="expiry"
                value={cardState.expiry}
                onChange={handleInputCardChange}
                onFocus={handleInputCardFocus}
              />

              <TextField
                type="tel"
                placeholder="CVC"
                name="cvc"
                inputProps={{
                  pattern: "\\d{3,4}", // 3 to 4 digits
                }}
                value={cardState.cvc}
                onChange={handleInputCardChange}
                onFocus={handleInputCardFocus}
              />
            </Box>
          </Box>
        </Box>
      </>
    );
  }

  if (campaign) {
    console.log(campaign);

    const backgroundStyle = !campaign.multimedia_file
      ? { backgroundColor: campaign.background_colour }
      : {};

    return (
      <Box height="100vh" position="relative" overflow="hidden">
        {campaign.multimedia_file && (
          <video
            autoPlay
            loop
            muted
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              zIndex: -9,
            }}
          >
            <source
              src={`${window.location.origin}${campaign.multimedia_file}`}
              // src="http://localhost:8000/media/multimedia/e5d3e061-a979-46d0-9571-eec89030699d.mp4"
              type="video/mp4"
            />
            {/* Your browser does not support the video tag. your browser does not suppo */}
          </video>
        )}
        <Box
          position="fixed"
          left={0}
          right={0}
          display="flex"
          justifyContent="center"
          paddingX={10}
          sx={backgroundStyle}
        >
          <Box
            display="flex"
            flexDirection="column"
            // bgcolor={campaign.background_colour}
            justifyContent="center"
            alignItems="center"
            paddingTop={10}
            gap={10}
            zIndex={1}
          >
            {campaign.logo && (
              <Box maxWidth={600}>
                <img
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                  src={`${window.location.origin}${campaign.logo}`}
                  alt="Campaign Logo"
                />
              </Box>
            )}
            <Typography
              color={campaign.text_colour}
              variant="h1"
              fontSize={50}
              textAlign="center"
            >
              {campaign.heading}
            </Typography>
            <Box display="flex" flexWrap="wrap" gap={5}>
              {campaign.buttons.map((btn, i) => (
                <DonationButton
                  onclick={() => {
                  setSelectedAmount(btn);

                    handleAmountBtnClicked(btn)
                    
                  }}
                  key={i}
                  value={btn}
                />
              ))}
              {customAmount && (
                <DonationButton
                  onclick={() => {
                    console.log(customAmount, " <---- custom amount")
                    
                    handleAmountBtnClicked(customAmount)}}
                  value={customAmount}
                />
              )}
            </Box>
            {campaign.custom_amount && (
              <TextField
                type="number"
                value={customAmount}
                onChange={(e) => {
                  let value = e.target.value;

                  // Remove leading zeros
                  value = value.replace(/^0+/, "");

                  // Only update state if value is greater than 0
                  if (value && Number(value) > 0) {
                    setCustomAmount(value);
                  } else {
                    // Reset to empty if the value is invalid (0 or empty string)
                    setCustomAmount("");
                  }
                }}
                inputProps={{ min: 1, max: 100, step: 1 }}
                placeholder="Enter Custom Amount"
                sx={{ minWidth: 200 }}
              />
            )}
          </Box>
        </Box>
      </Box>
    );
  }

  // return null;
};

export default WebCampaign;

import { apiSlice } from './apiSlice';
import { setCredentials, logout } from './authSlice';

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: '/token/',
        method: 'POST',
        body: data,
      }),
    //   async onQueryStarted(arg, { dispatch, queryFulfilled }) {
    //     try {
    //       const { data } = await queryFulfilled;
    //       dispatch(setCredentials(data));
    //     } catch (error) {
    //       console.error('Failed to login:', error);
    //     }
    //   },
    }),
    getUser: builder.mutation({
      query: () => ({
        url: '/get-user/',
        method: 'POST',
      }),
    }),
    test: builder.mutation({
      query: () => ({
        url: '/test-api/',
        method: 'POST',
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: '/logout',
        method: 'POST',
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(logout());
        } catch (error) {
          console.error('Failed to logout:', error);
        }
      },
    }),
    register: builder.mutation({
      query: (data) => ({
        url: '/register/',
        method: 'POST',
        body: data,
      }),
    }),
    activate: builder.mutation({
      query: ({ uidb64, token }) => ({
        url: '/activate/',
        method: 'POST',
        body: { uidb64, token },  
      }),
    }),
    forgotPassword: builder.mutation({
      query: (data) => ({
        url: '/forgot-password/',
        method: 'POST',
        body: data,  
      }),
    }),
    resetPassword: builder.mutation({
      query: (data) => ({
        url: '/reset-password/',
        method: 'POST',
        body: data,  
      }),
    }),
    updateUser: builder.mutation({
      query: (data) => ({
        url: '/profile',
        method: 'PUT',
        body: data,
      }),
    }),
    sumupLogin: builder.mutation({
      query: (data) => ({
        url: '/sumup-login/',
        method: 'POST',
        body: data,
      }),
    }),
    sumupCallback: builder.mutation({
      query: (data) => ({
        url: '/sumup-callback/',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useTestMutation,
  useLogoutMutation,
  useRegisterMutation,
  useUpdateUserMutation,
  useGetUserMutation,
  useActivateMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useSumupLoginMutation,
  useSumupCallbackMutation,
} = userApiSlice;

import React, { useState, useEffect } from "react";
import { Container, TextField, Button, Typography, Box } from "@mui/material";
import toaster from "../../utils/toast";
import { Link, useNavigate } from "react-router-dom";
import './style.scss'
import olivepod2 from '../../images/olivepod2.webp'
import { useFormik } from "formik";
import { forgotPasswordSchema, loginSchema } from "../../utils/form_schemas";
import {useLoginMutation, useTestMutation, useGetUserMutation, useForgotPasswordMutation} from '../../state/slices/usersApiSlice'
import { setCredentials } from "../../state/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../state/slices/userSlice";
import { grey } from "@mui/material/colors";
import { TailSpin } from "react-loader-spinner";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState(null);
//   const handleSubmit = (event) => {
//     event.preventDefault();
//     // Implement your login logic here
//     if (email !== "" && password !== "") {
//       toaster("success", " good nicce");
//     } else {
//       toaster("error", " please provide valid");
//     }
//   };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [forgotPassword, {data, error, isLoading}] = useForgotPasswordMutation();

      



  const {isAuthenticated} = useSelector(state => state.auth)


 
  useEffect(() => {
    setMsg(null)
    if (isAuthenticated) {
      // navigate('/');
      window.location.href = '/';

      // window.location.reload()
    }
  }, [isAuthenticated, navigate]);

  

  const submitHandler = async (req, res) => {
    console.log('email:  ', values.email)
    try {
      const data = {email: values.email}
      const response = await forgotPassword(data).unwrap()      
      
        setMsg(response.msg)




    } catch (error) {
      toaster('error', error.data?.msg)
        console.log("eeeeeeeeeee",error)
    }
    
  }


  const  initialValues = {
    email: '',
  }
  const {values, errors, touched, handleBlur, handleChange, handleSubmit} =  useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: submitHandler

  })


  

////////////////=======================================================================///////////////////////////////////////////////////
  return (
    <div className="login-container" >
      <Box bgcolor="red" className="gradient-box" sx={{flexBasis:'30%', display:'flex', justifyContent:'center', alignItems:'center'}} >

            <img src={olivepod2} height="70px" />
        
      </Box>
      <Container component="main" maxWidth="xs" sx={{flexBasis:'70%', display:'flex', justifyContent:'center', alignItems:'center'}}  >

{
  isLoading? (<TailSpin color="#56CABB" width={80} height={80} />):
        
          msg? (<h1>{msg}</h1>):
        <Box
          sx={{
            // marginTop: -10,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h4">
            Please enter your email
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              type="email"
              name="email"
              autoComplete="email"
              
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={errors?.email && touched?.email ? errors.email : null}
              error={errors.email && touched.email ? true : false}
            />

            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <Link to="/login">
               Back to login?
              </Link>
            </Box>

            <Button
              onClick={handleSubmit}
              fullWidth
              variant="contained"
              disabled={isLoading}
              sx={{ mt: 3, mb: 2, fontSize: 14,  }}
            >
              {isLoading? '. . . .' : 'Reset password'} 
            </Button>
          </Box>
          {/* <p onClick={handleTest} >TEst</p> */}
        </Box>
        
}

    
      </Container>
    </div>
  );
};

export default ForgotPassword;

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom"; // or 'useNavigate' if you're using React Router v6
import axios from "axios";
import { useSumupCallbackMutation } from "../state/slices/usersApiSlice";

const SumUpCallback = () => {
  const navigate = useNavigate(); // for redirecting later

  const [sumupCallback, { data, error, isLoading }] =
    useSumupCallbackMutation();

  useEffect(() => {
    callBack();
  }, [navigate]);

  const callBack = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");

      if (code) {
        const response = await sumupCallback().unwrap();
        if (response.status === 200) {
          navigate("/");
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return <div>Authenticating...</div>;
};

export default SumUpCallback;

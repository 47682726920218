import {
  Box,
  Button,
  IconButton,
  Typography,
  useTheme,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { tokens } from "../../theme";
import { mockTransactions } from "../../data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import VolunteerActivismOutlinedIcon from "@mui/icons-material/VolunteerActivismOutlined";
import PointOfSaleOutlinedIcon from "@mui/icons-material/PointOfSaleOutlined";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import ProgressCircle from "../../components/ProgressCircle";

import { useGetReportsMutation } from "../../state/slices/reportsApiSlice";
import { setReports } from "../../state/slices/reportsSlice";
import { setBasicData } from "../../state/slices/dashboardSlice";
import { useGetBasicDataMutation } from "../../state/slices/dashboardApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { green } from "@mui/material/colors";
import SumUpLogin from "../../components/SumupLogin";

const Dashboard = () => {
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [transactionsToday, setTransactionsToday] = useState(0);
  const [transactionsLastMonth, setTransactionsLastMonth] = useState(0);
  const [transactionsLastWeek, setTransactionsLastWeek] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalAmountLastMonth, setTotalAmountLastMonth] = useState(0);
  const [totalAmountToday, setTotalAmountToday] = useState(0);
  const [failedDonationsLastWeek, setFailedDonationsLastWeek] = useState(0);
  const [selectedDateRange, setSelectedDateRange] = useState("all");
  const [selectedDateRangeBar, setSelectedDateRangeBar] = useState("all");

  const [selectedProductSummary, setSelectedProductSummary] = useState("");

  const [transactionInSelectedSummury, setTransactionInSelectedSummury] =
    useState("-");
  const [donationsInSelectedSummury, setDonationsInSelectedSummury] =
    useState("-");

  const [filteredTransactions, setFilteredTransactions] = useState("-");
  const [barChartData, setBarChartData] = useState([]);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const basicData = useSelector((state) => state.dashboard.basicData);
  const [getBasicData, { data, error, isLoading }] = useGetBasicDataMutation();
  const reportData = useSelector((state) => state.report.reports);

  useEffect(() => {
    fetchBasicData();
    processTransactionData(reportData);

    // console.log(reportData, "console from das");
  }, []);

  const productSummaries = [
    ...new Set(reportData?.map((tra) => tra.product_summary)),
  ];

  // Add custom "All" item at the beginning of the list
  const dropdownItems = ["All", ...productSummaries];

  const fetchBasicData = async () => {
    try {
      const basicData = await getBasicData();
      console.log(basicData);
      dispatch(setBasicData(basicData));
    } catch (error) {
      console.log(error);
    }
  };

  const handleDateRangeChange = (event) => {
    setSelectedDateRange(event.target.value);
  };

  const handleDateRangeChangeBar = (event) => {
    setSelectedDateRangeBar(event.target.value);
  };

  const handleProductSummaryChange = (event) => {
    setSelectedProductSummary(event.target.value);
  };

  const filterTransactions = () => {
    // Initialize filteredTransactions with reportData (ensure it's an array)
    let filteredTransactions = Array.isArray(reportData) ? reportData : [];

    // Filter by selected date range
    if (selectedDateRange === "lastWeek") {
      filteredTransactions = Array.isArray(transactionsLastWeek)
        ? transactionsLastWeek
        : [];
    } else if (selectedDateRange === "lastMonth") {
      filteredTransactions = Array.isArray(transactionsLastMonth)
        ? transactionsLastMonth
        : [];
    }

    // Filter by product summary if selected

    if (selectedProductSummary === "All") {
      filteredTransactions = filteredTransactions;
    } else if (selectedProductSummary) {
      filteredTransactions = filteredTransactions.filter(
        (transaction) => transaction.product_summary === selectedProductSummary
      );
    }

    // Sum amounts by timestamp
    const summedTransactions = filteredTransactions.reduce(
      (acc, transaction) => {
        const date = new Date(transaction.timestamp)
          .toISOString()
          .split("T")[0];
        if (!acc[date]) {
          acc[date] = 0;
        }
        acc[date] += transaction.amount;
        return acc;
      },
      {}
    );

    console.log(summedTransactions, ' form line chart');

    const donation_in_selected = Object.values(summedTransactions).reduce(
      (acc, value) => acc + value,
      0
    );
    console.log(donation_in_selected, "donation_in_selected");
    const transactions_in_selected = Object.keys(summedTransactions).length;
    console.log(transactions_in_selected, "transactions_in_selected");
    // setDonationsInSelectedSummury(donation_in_selected);
    // setTransactionInSelectedSummury(transactions_in_selected);

    // Format data into the desired structure
    const chartData = Object.keys(summedTransactions)?.map((key) => ({
      x: key,
      y: summedTransactions[key],
    }));

    const finalData = [
      {
        id: "donations",
        color: green[300],
        data: chartData,
      },
    ];

    return {
      filteredTransactions: finalData,
      donation_in_selected: donation_in_selected,
      transactions_in_selected: transactions_in_selected,
    };
  };


  const filterTransactionsBar = () => {
    // Initialize filteredTransactions with reportData (ensure it's an array)
    let filteredTransactions = Array.isArray(reportData) ? reportData : [];

    // Filter by selected date range
    if (selectedDateRangeBar === "lastWeek") {
      filteredTransactions = Array.isArray(transactionsLastWeek)
        ? transactionsLastWeek
        : [];
    } else if (selectedDateRangeBar === "lastMonth") {
      filteredTransactions = Array.isArray(transactionsLastMonth)
        ? transactionsLastMonth
        : [];
    }

  
    return filteredTransactions;
  };


  useEffect(() => {
    const {
      filteredTransactions,
      donation_in_selected,
      transactions_in_selected,
    } = filterTransactions();
    setFilteredTransactions(filteredTransactions);
    setDonationsInSelectedSummury(donation_in_selected);
    setTransactionInSelectedSummury(transactions_in_selected);
    // setBarChartData(prepareBarChartData(filteredTransactions));
  }, [selectedDateRange, selectedProductSummary, reportData]);

  const processTransactionData = (reportData) => {
    let total_amount = 0;
    let total_amount_lastmonth = 0;
    let total_amount_today = 0;

    console.log("Processing transaction data");
    try {
      console.log("Data received:", reportData);
      const alltransactions = reportData;
      const success_transactions = alltransactions.filter(
        (transaction) => transaction.status === "SUCCESSFUL"
      );
      const transactions = success_transactions;
      const totalCount = transactions.length;

      const lastWeekStart = new Date();
      lastWeekStart.setDate(lastWeekStart.getDate() - 7);
      const lastWeekTransactions = transactions.filter((transaction) => {
        const transactionDate = new Date(transaction.timestamp);
        return transactionDate > lastWeekStart;
      });

      const alllastWeekTransactions = alltransactions.filter((transaction) => {
        const transactionDate = new Date(transaction.timestamp);
        return transactionDate > lastWeekStart;
      });

      const lastMonthStart = new Date();
      lastMonthStart.setDate(lastMonthStart.getDate() - 30);
      const lastMonthTransactions = transactions.filter((transaction) => {
        const transactionDate = new Date(transaction.timestamp);
        return transactionDate > lastMonthStart;
      });

      const todayStart = new Date();
      todayStart.setHours(0, 0, 0, 0);
      const todayTransactions = transactions.filter((transaction) => {
        const transactionDate = new Date(transaction.timestamp);
        return transactionDate >= todayStart;
      });

      lastMonthTransactions.forEach((transaction) => {
        total_amount_lastmonth += transaction.amount;
      });

      todayTransactions.forEach((transaction) => {
        total_amount_today += transaction.amount;
      });

      transactions.forEach((transaction) => {
        total_amount += transaction.amount;
      });

      const failed_donations_last_week = alllastWeekTransactions.filter(
        (transaction) => transaction.status === "FAILED"
      );

      setTotalTransactions(totalCount);
      setTransactionsToday(todayTransactions.length);
      setTransactionsLastMonth(lastMonthTransactions.length);
      setTransactionsLastWeek(lastWeekTransactions);
      setTotalAmount(Math.round(total_amount));
      setTotalAmountLastMonth(total_amount_lastmonth);
      setTotalAmountToday(total_amount_today);
      setFailedDonationsLastWeek(failed_donations_last_week.length);
    } catch (error) {
      console.error("Processing error:", error);
    }
  };

  const prepareBarChartData = (filteredTransactions) => {
    const summaryMap = {};
    console.log(filteredTransactions, "Bar Chart DAta");

    filteredTransactions?.forEach((tx) => {
      if (!summaryMap[tx.product_summary]) {
        summaryMap[tx.product_summary] = 0;
      }
      summaryMap[tx.product_summary] += tx.amount;
    });

    const BarChartData = Object.keys(summaryMap)?.map((key) => ({
      product_summary: key,
      value: summaryMap[key],
    }));
    console.log(BarChartData, "Bar Chart DAta222");
    return BarChartData;
  };

  useEffect(() => {
    const filteredTransactionsBar = filterTransactionsBar();
    setBarChartData(prepareBarChartData(filteredTransactionsBar));
  }, [selectedDateRangeBar]);

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />
<SumUpLogin/>

        {/* <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Download Reports
          </Button>
        </Box> */}
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={totalAmountToday}
            subtitle="Total Today"
            icon={
              <VolunteerActivismOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={totalAmountLastMonth}
            subtitle="Total last 30 days"
            icon={
              <VolunteerActivismOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={totalAmount}
            subtitle="Total Donated"
            icon={
              <VolunteerActivismOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>

        {/* ROW 1.1 */}
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={transactionsToday}
            subtitle="No. of transaction today"
            icon={
              <PointOfSaleOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={transactionsLastMonth}
            subtitle="No. of transaction 30 today"
            icon={
              <PointOfSaleOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 4"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={totalTransactions}
            subtitle="Total No. of transactions"
            icon={
              <PointOfSaleOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>

        {/* ROW 2 */}
        <Box
          gridColumn="span 8"
          gridRow="span 3"
          backgroundColor={colors.primary[400]}
        >
          {/* Filter Section */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt="5px"
          >
            <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="date-range-select-label">Date Range</InputLabel>
              <Select
                labelId="date-range-select-label"
                id="date-range-select"
                value={selectedDateRange}
                onChange={handleDateRangeChange}
                label="Date Range"
              >
                <MenuItem value="all">All Time</MenuItem>
                <MenuItem value="lastWeek">Last Week</MenuItem>
                <MenuItem value="lastMonth">Last Month</MenuItem>
              </Select>
            </FormControl>

            <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="product-summary-select-label">
                Product Summary
              </InputLabel>
              <Select
                labelId="product-summary-select-label"
                id="product-summary-select"
                value={selectedProductSummary}
                onChange={handleProductSummaryChange}
                label="Product Summary"
              >
                {/* Add MenuItem components here for each product summary */}
                {dropdownItems?.map((tra, i) => (
                  <MenuItem key={i} value={tra}>
                    {" "}
                    {tra}{" "}
                  </MenuItem>
                ))}
                {/* <MenuItem value=""><em>All</em></MenuItem>
            <MenuItem value="productB">Product B</MenuItem> */}
                {/* Add more items as needed */}
              </Select>
            </FormControl>
          </Box>
          <Box
            mt="25px"
            p="0 30px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            {/* <Box>
              <Typography variant="h5" fontWeight="600" color={colors.grey[100]}>
                Donations
              </Typography>
              <Typography variant="h3" fontWeight="bold" color={colors.greenAccent[500]}>
                GHS {totalAmount}
              </Typography>
            </Box> */}
            {/* <Box>
              <IconButton>
                <DownloadOutlinedIcon sx={{ fontSize: "26px", color: colors.greenAccent[500] }} />
              </IconButton>
            </Box> */}
          </Box>

              
          <Box height={300} mt="-20px">
            {filteredTransactions.length > 0 &&
            <LineChart isDashboard={true} data={filteredTransactions} />
            }

          </Box>

        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            flexDirection="column"
            gap={4}
            justifyContent="space-between"
            alignItems="center"
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              No of Transactions in selected Campaign
            </Typography>
            <Typography color={colors.grey[100]} variant="h4" fontWeight="600">
              {transactionInSelectedSummury}
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            gap={4}
            justifyContent="space-between"
            alignItems="center"
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Total Donations In Selected Campaign
            </Typography>
            <Typography color={colors.grey[100]} variant="h4" fontWeight="600">
              £ {donationsInSelectedSummury}
            </Typography>
          </Box>
        </Box>

        {/* ROW 3 */}

        <Box
          gridColumn="span 12"
          gridRow="span 4"
          backgroundColor={colors.primary[400]}
        >
          <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="date-range-select-label">Date Range</InputLabel>
            <Select
              labelId="date-range-select-label"
              id="date-range-select"
              value={selectedDateRangeBar}
              onChange={handleDateRangeChangeBar}
              label="Date Range"
            >
              <MenuItem value="all">All Time</MenuItem>
              <MenuItem value="lastWeek">Last Week</MenuItem>
              <MenuItem value="lastMonth">Last Month</MenuItem>
            </Select>
          </FormControl>

          <Typography
            variant="h5"
            fontWeight="600"
            sx={{ p: "5px 30px 0 30px" }}
          >
            Campaigns
          </Typography>
          <Box  mt="-20px">
            <BarChart data={barChartData} />
          </Box>
        </Box>

        {/* row 2nd last */}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={`${basicData?.donations_currentmonth}`}
            subtitle="Total Gift Aid Donations"
            extratext="This Month"
            icon={
              <VolunteerActivismOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={`${basicData?.uplift_currentmonth}`}
            subtitle="Uplift From Gift"
            extratext="This Month"
            icon={
              <VolunteerActivismOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={`${basicData?.donations_lastmonth}`}
            subtitle="Total Gift Aid Donations"
            extratext="Last Month"
            icon={
              <VolunteerActivismOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={`${basicData?.uplift_lastmonth}`}
            subtitle="Uplift From Gift Aid"
            icon={
              <VolunteerActivismOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        {/* last 2nd last */}
        <Box
          gridColumn="span 12"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
          marginBottom={2}
        >
          <StatBox
            title={`${failedDonationsLastWeek}`}
            subtitle="Failed Donations Last Week"
            icon={
              <VolunteerActivismOutlinedIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;

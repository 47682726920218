import { useParams } from "react-router-dom";
import { useActivateMutation } from "../../state/slices/usersApiSlice";
import { useEffect, useState } from "react";
import toaster from "../../utils/toast";

const ActivateAccount = () => {
  const { uidb64, token } = useParams();
  const [activate, { isLoading, error }] = useActivateMutation();
  
  const [message, setMessage] = useState();

  console.log('this is active account page')

  useEffect(() => {
    activateAccount()
  }, [uidb64, token, activate]);
  
  const activateAccount = async () => {
   const response = await activate({ uidb64, token }).unwrap();
   
    setMessage(response.message)
   
  };

  return (
    <div>
      {isLoading ? "Please Wait" : 
        ( <h1>{message}</h1> )
      }
      
    </div>
  );
};

export default ActivateAccount;

import { apiSlice } from './apiSlice';

export const checkoutApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createCheckout: builder.mutation({
      query: (data) => ({
        url: '/create-checkout/',
        method: 'POST',
        body: data,
      }),
  }),
  updatePaymentStatus: builder.mutation({
    query: (data) => ({
      url: '/update-payment-status/',
      method: 'POST',
      body: data,
    }),
}),
})
});
export const {
 useCreateCheckoutMutation,
 useUpdatePaymentStatusMutation,
} = checkoutApiSlice;

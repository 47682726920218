import { apiSlice } from "./apiSlice";
import { setCredentials, logout } from "./authSlice";

export const fileApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    downloadReportsExcel: builder.mutation({
      query: (data) => ({
        url: "/download-reports-excel/",
        method: "POST",
        body: data,
      }),
      // Add a response handler to treat it as a blob
      // responseHandler: (response) => response.blob(),
    }),
    updateFee: builder.mutation({
      query: (data) => ({
        url: "/update-fee/",
        method: "POST",
        body: data,
      }),
    }),
  }),
});
export const { useUpdatePincodeMutation, useDownloadReportsExcelMutation } =
  fileApiSlice;

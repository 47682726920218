import React, { useState, useEffect } from "react";
import { Container, TextField, Button, Typography, Box } from "@mui/material";
import toaster from "../../utils/toast";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import { useFormik } from "formik";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { Link } from "react-router-dom";
import "./style.scss";
import olivepod2 from "../../images/olivepod2.webp";
import Select from "react-select";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ReCAPTCHA from "react-google-recaptcha";
import { loginSchema, registerSchema } from "../../utils/form_schemas";
import { useRegisterMutation } from "../../state/slices/usersApiSlice";

const Register = () => {
  const [countries, setCountries] = useState([]);
  const [selectedcountry_for_tax_purpose, setSelectedcountry_for_tax_purpose] = useState({});
  const [documentOfProof, setDocumentOfProof] = useState(null);

  const [register, { data, error, isLoading }] = useRegisterMutation();


  useEffect(() => {
    fetch(
      "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
    )
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries);
        setSelectedcountry_for_tax_purpose(data.userSelectValue);
      });
  }, []);

  const initialValues = {
    first_name: "",
    last_name: "",
    organisation_name: "",
    charity_number: "",
    country_for_tax_purpose: "",
    address: "",
    telephone: "",
    email: "",
    password: "",
    confirm_password: "",
    packages: "1",
    no_of_devices: "1",
    subpackage: "1",
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: registerSchema,
    onSubmit: async (values) => {
      console.log('in on submit')
      values.country_for_tax_purpose = selectedcountry_for_tax_purpose.label;
   
      // if (documentOfProof) {
      //   values.document_of_proof = documentOfProof;
      // }

      values.package = values.packages

      console.log(values, ' this is values')
      // const formData = new FormData();
      // for (const key in values) {
      //   formData.append(key, values[key]);
      // }
      // formData.append("document_of_proof", documentOfProof);

      // console.log(formData.get('document_of_proof'));
      const formData = new FormData();
      for (const key in values) {
        formData.append(key, values[key]);
      }
      formData.append("document_of_proof", documentOfProof);

      try {
        const res = await register(formData).unwrap();
        console.log(res, "<------------ this is reponsoe test");
        if (res.status === 201){
        toaster('success', res.msg )

        }
      } catch (error) {
        console.log(error)
        if (error.data?.email){
          toaster('error', error.data?.email[0] )
        }else{
          toaster('error', 'Account not created')

        }
      }


    },
  });

  const [packages, setPackages] = useState("1");
  const [subpackage, setsubpackage] = useState();

  return (
    <div className="register-container">
      <Box
        bgcolor="red"
        className="gradient-box"
        sx={{
          flexBasis: "30%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={olivepod2} height="70px" />
      </Box>
      <Container
        component="main"
        sx={{
          flexBasis: "70%",
          display: "flex",
          justifyContent: "center",
          marginTop: 5,
        }}
      >
        <Box
          className="form-box"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h4">
            Please enter your details for signing up
          </Typography>
          <Box
            component="form"
            noValidate
            sx={{ mt: 1, width: "100%" }}
          >
            <Box sx={{ display: "flex", gap: 2 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="first_name"
                label="First Name"
                name="first_name"
                autoComplete="first_name"
                value={values.first_name}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={
                  errors?.first_name && touched?.first_name
                    ? errors.first_name
                    : null
                }
                error={errors.first_name && touched.first_name ? true : false}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="last_name"
                label="Last Name"
                name="last_name"
                autoComplete="last_name"
                value={values.last_name}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={
                  errors?.last_name && touched?.last_name ? errors.last_name : null
                }
                error={errors.last_name && touched.last_name ? true : false}
              />
            </Box>
            <TextField
              margin="normal"
              required
              fullWidth
              id="organisation_name"
              label="Charity/Organisation Name"
              name="organisation_name"
              autoComplete="organisation_name"
              value={values.organisation_name}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errors?.organisation_name && touched?.organisation_name
                  ? errors.organisation_name
                  : null
              }
              error={
                errors.organisation_name && touched.organisation_name
                  ? true
                  : false
              }
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="charity_number"
              label="Charity/Organisation Number"
              name="charity_number"
              autoComplete="charity_number"
              value={values.charity_number}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errors?.charity_number && touched?.charity_number
                  ? errors.charity_number
                  : null
              }
              error={
                errors.charity_number && touched.charity_number
                  ? true
                  : false
              }
            />
            <Select
              options={countries}
              onChange={(value) => setSelectedcountry_for_tax_purpose(value)}
              value={selectedcountry_for_tax_purpose}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="address"
              label="Charity/Organisation Address"
              name="address"
              autoComplete="address"
              value={values.address}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errors?.address && touched?.address ? errors.address : null
              }
              error={errors.address && touched.address ? true : false}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="telephone"
              label="Charity/Organisation telephone"
              name="telephone"
              autoComplete="telephone"
              value={values.telephone}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={errors?.telephone && touched?.telephone ? errors.telephone : null}
              error={errors.telephone && touched.telephone ? true : false}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={errors?.email && touched?.email ? errors.email : null}
              error={errors.email && touched.email ? true : false}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="new-password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errors?.password && touched?.password ? errors.password : null
              }
              error={errors.password && touched.password ? true : false}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="confirm_password"
              label="Confirm Password"
              type="password"
              id="confirm_password"
              autoComplete="new-password"
              value={values.confirm_password}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={
                errors?.confirm_password && touched?.confirm_password
                  ? errors.confirm_password
                  : null
              }
              error={
                errors.confirm_password && touched.confirm_password ? true : false
              }
            />
        <div className="doc_upload">
              <label htmlFor="doc_upload">
                Upload any supporting document for your charitable status
              </label>
              <input
                id="doc_upload"
                type="file"
                onChange={(event) => {
                  setDocumentOfProof(event.currentTarget.files[0]);
                  setFieldValue(
                    "document_of_proof",
                    event.currentTarget.files[0]
                  );
                }}
              />
            </div>

            <FormLabel id="packages" sx={{ marginTop: 3 }}>
              Choose Packages:
            </FormLabel>
            <RadioGroup
              aria-labelledby="packages"
              name="packages"
              value={values.packages}
              onChange={(e) => {
                setPackages(e.target.value);
                setFieldValue("packages", e.target.value);
              }}
            >
              <FormControlLabel
                value="3"
                control={<Radio />}
                label="Free device package (£20/month) Minimum 6 months contract"

              />
              <FormControlLabel
                value="1"
                control={<Radio />}
                label="Per Device Licence"
              />
              {values.packages === "1" && (
                <TextField
                  margin="normal"
                  required
                  sx={{ maxWidth: 150, ml: 10 }}
                  id="no_of_devices"
                  label="No of Devices"
                  type="number"
                  name="no_of_devices"
                  autoComplete="no_of_devices"
                  value={values.no_of_devices}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={
                    errors?.no_of_devices && touched?.no_of_devices
                      ? errors.no_of_devices
                      : null
                  }
                  error={
                    errors.no_of_devices && touched.no_of_devices ? true : false
                  }
                />
              )}
              <FormControlLabel
                value="2"
                control={<Radio />}
                label="Unlimited Licence (Unlimited Devices)"
              />
            </RadioGroup>
            {values.packages === "2" && (
              <RadioGroup
                aria-label="subpackage"
                name="subpackage"
                value={values.subpackage}
                onChange={(e) => {
                  setsubpackage(e.target.value);
                  setFieldValue("subpackage", e.target.value);
                }}
                sx={{ ml: 10 }}
              >
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  label="£ 0 - £ 7000 (£7.50/month excl.tax)"
                />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label="£ 7000 - £ 10000 (£10/month excl.tax)"
                />
                <FormControlLabel
                  value="3"
                  control={<Radio />}
                  label="£ 10000 - £ 20000 (£15/month excl.tax)"
                />
                <FormControlLabel
                  value="4"
                  control={<Radio />}
                  label="£ 20000 - £ 30000 (£25/month excl.tax)"
                />
                <FormControlLabel
                  value="5"
                  control={<Radio />}
                  label="£ 30000 - £ 40000 (£35/month excl.tax)"
                />
                <FormControlLabel
                  value="6"
                  control={<Radio />}
                  label="£ 40000 - £ 50000 (£45/month excl.tax)"
                />
              </RadioGroup>
            )}

            <ReCAPTCHA
              sitekey="6LfZFSAhAAAAAIErZoUur4uNNLfdl--lsztjpwtR"
              onChange={(value) => console.log("Captcha value:", value)}
            />
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <Link to="/login">
                Already have an account?
              </Link>
            </Box>
            <Button
              // type="submit"
              onClick={handleSubmit}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, fontSize: 14 }}
              disabled={isLoading}
            >
              {isLoading? 'please wait ...': 'Sign Up'} 
            </Button>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default Register;
